var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "grey lighten-3 pa-md-6 pa-2",
      attrs: { "fill-height": "" }
    },
    [
      _c(
        "v-card",
        {
          staticClass: "mx-auto pa-4 pa-md-8",
          attrs: { outlined: "", elevation: "2", "min-width": "50vw" }
        },
        [
          _c(
            "v-form",
            {
              ref: "signupForm",
              attrs: { "lazy-validation": "" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.anonymsToUser()
                }
              },
              model: {
                value: _vm.valid,
                callback: function($$v) {
                  _vm.valid = $$v
                },
                expression: "valid"
              }
            },
            [
              _c(
                "v-row",
                { attrs: { align: "center", justify: "center" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pa-lg-4 pa-2 hidden-sm-and-down",
                      attrs: { cols: "12", md: "6" }
                    },
                    [
                      _c("lottie-player", {
                        staticStyle: { width: "400px", height: "400px" },
                        attrs: {
                          src:
                            "https://assets4.lottiefiles.com/packages/lf20_dyq0qz89/data.json",
                          background: "transparent",
                          speed: "1",
                          autoplay: "",
                          loop: ""
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pa-lg-6 pa-xl-10 pa-2",
                      attrs: { cols: "12", lg: "6", md: "6" }
                    },
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "text-h4 mb-5 font-weight-black" },
                        [_vm._v("Signup")]
                      ),
                      _c("v-text-field", {
                        attrs: {
                          label: "Full Name",
                          placeholder: "Your full name - 4 chars (minimum)",
                          rules: _vm.nameRules,
                          filled: "",
                          clearable: "",
                          type: "text",
                          required: ""
                        },
                        model: {
                          value: _vm.name,
                          callback: function($$v) {
                            _vm.name = $$v
                          },
                          expression: "name"
                        }
                      }),
                      _c("v-text-field", {
                        attrs: {
                          label: "Email",
                          placeholder: "A valid email address",
                          rules: _vm.emailRules,
                          filled: "",
                          clearable: "",
                          type: "text",
                          required: ""
                        },
                        model: {
                          value: _vm.email,
                          callback: function($$v) {
                            _vm.email = $$v
                          },
                          expression: "email"
                        }
                      }),
                      _c("v-text-field", {
                        attrs: {
                          label: "Password",
                          placeholder:
                            "Minimum 6 characters, at least one capital letter and a number",
                          rules: _vm.passwordRules,
                          type: _vm.passwordFieldType,
                          "append-icon": _vm.passwordIcon,
                          filled: "",
                          clearable: "",
                          required: ""
                        },
                        on: { "click:append": _vm.unmaskPassword },
                        model: {
                          value: _vm.password,
                          callback: function($$v) {
                            _vm.password = $$v
                          },
                          expression: "password"
                        }
                      }),
                      _c("v-checkbox", {
                        attrs: { color: "red" },
                        scopedSlots: _vm._u([
                          {
                            key: "label",
                            fn: function() {
                              return [
                                _c(
                                  "div",
                                  [
                                    _vm._v(" I agree to the "),
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { bottom: "" },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "a",
                                                  _vm._g(
                                                    {
                                                      attrs: {
                                                        target: "_blank",
                                                        href: "/terms"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                        }
                                                      }
                                                    },
                                                    on
                                                  ),
                                                  [_vm._v(" Terms of Service ")]
                                                )
                                              ]
                                            }
                                          }
                                        ])
                                      },
                                      [_vm._v(" Opens in new window ")]
                                    ),
                                    _vm._v(" of Quizi ")
                                  ],
                                  1
                                )
                              ]
                            },
                            proxy: true
                          }
                        ]),
                        model: {
                          value: _vm.terms,
                          callback: function($$v) {
                            _vm.terms = $$v
                          },
                          expression: "terms"
                        }
                      }),
                      _c(
                        "v-row",
                        { attrs: { align: "center" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    large: "",
                                    block: "",
                                    color: "success",
                                    type: "submit",
                                    disabled: !_vm.valid || !_vm.terms
                                  }
                                },
                                [_vm._v(" Create User ")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    large: "",
                                    block: "",
                                    color: "secondary",
                                    outlined: "",
                                    to: "/dashboard"
                                  }
                                },
                                [_vm._v(" Back ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("Loading", {
        attrs: { loading: _vm.loading, text: _vm.signupLoadingText }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }