<template>
  <v-container fill-height class="grey lighten-3 pa-md-6 pa-2">
    <v-card class="mx-auto pa-4 pa-md-8" outlined elevation="2" min-width="50vw">
      <v-form ref="signupForm" v-model="valid" lazy-validation @submit.prevent="anonymsToUser()">
        <v-row align="center" justify="center">
          <v-col cols="12" md="6" class="pa-lg-4 pa-2 hidden-sm-and-down">
            <lottie-player
              src="https://assets4.lottiefiles.com/packages/lf20_dyq0qz89/data.json"
              background="transparent"
              speed="1"
              style="width: 400px; height: 400px"
              autoplay
              loop
            ></lottie-player>
            <!-- <v-img src="@/assets/signup.svg" contain></v-img> -->
          </v-col>
          <v-col cols="12" lg="6" md="6" class="pa-lg-6 pa-xl-10 pa-2">
            <v-card-title class="text-h4 mb-5 font-weight-black">Signup</v-card-title>

            <v-text-field
              v-model="name"
              label="Full Name"
              placeholder="Your full name - 4 chars (minimum)"
              :rules="nameRules"
              filled
              clearable
              type="text"
              required
            ></v-text-field>

            <v-text-field
              v-model="email"
              label="Email"
              placeholder="A valid email address"
              :rules="emailRules"
              filled
              clearable
              type="text"
              required
            ></v-text-field>

            <v-text-field
              v-model="password"
              label="Password"
              placeholder="Minimum 6 characters, at least one capital letter and a number"
              :rules="passwordRules"
              :type="passwordFieldType"
              :append-icon="passwordIcon"
              filled
              clearable
              required
              @click:append="unmaskPassword"
            ></v-text-field>
            <v-checkbox color="red" v-model="terms">
              <template v-slot:label>
                <div>
                  I agree to the
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <a target="_blank" href="/terms" @click.stop v-on="on"> Terms of Service </a>
                    </template>
                    Opens in new window
                  </v-tooltip>
                  of Quizi
                </div>
              </template>
            </v-checkbox>
            <v-row align="center">
              <v-col cols="12" md="6">
                <v-btn large block color="success" type="submit" :disabled="!valid || !terms"> Create User </v-btn>
              </v-col>
              <v-col cols="12" md="6">
                <v-btn large block color="secondary" outlined to="/dashboard"> Back </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
    <Loading :loading="loading" :text="signupLoadingText" />
  </v-container>
</template>

<script>
import { convertGuestToUser } from "@/api/AuthApi"
import { mapState, mapMutations } from "vuex"
import { loginSignupMixin } from "@/mixins/loginSignupMixin.js"

export default {
  name: "Anonyms",
  data: () => ({
    signupSuccessMessage: "Welcome to Qizto, You've Signed Up",
    terms: false,
    link: { title: "Terms of Service", route: "/terms", target: "_blank" },
    name: "",
    signupLoadingText: "Creating your profile...",
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length >= 4) || "Name should be atleast 4 characters",
      (v) => /^[A-Za-z\s]+$/.test(v) || "Only Alphabets and Spaces allowed",
    ],
  }),

  mixins: [loginSignupMixin],

  computed: {
    ...mapState({
      currentUser: (state) => state.user.currentUser,
    }),
  },

  methods: {
    ...mapMutations({
      setUser: "SET_CURRENT_USER",
      showAlert: "SHOW_SNACKBAR",
    }),

    async anonymsToUser() {
      if (this.$refs.signupForm.validate()) {
        this.loading = !this.loading
        const email = this.email
        const password = this.password
        const name = this.name
        try {
          await convertGuestToUser(email, password, name)
          this.showAlert(this.signupSuccessMessage)
          this.$router.push("/dashboard")
        } catch (err) {
          this.showAlert(err.message)
        } finally {
          this.loading = !this.loading
        }
      }
    },
  },
}
</script>
